.pageLoading {
  width: 100vw;
  height: 100vh;
  position: relative;
  max-width: 750px;

  :global {
    .adm-spin-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.main {
  position: relative;
  max-width: 750px;
  width: 100%;
  background: linear-gradient(90deg, #1f7dea 0%, #e290c2 100%);
  min-height: 100vh;
  overflow-x: hidden;
  padding-bottom: 46px;

  .mainBanner {
    position: relative;

    .banner {
      position: relative;
      z-index: 3;
      width: 100%;
      margin-top: 45px;
    }

    .fireworks {
      &_left {
        top: -60px;
        position: absolute;
        width: calc(100% * 0.7);
        left: -70px;
      }

      &_right {
        position: absolute;
        width: calc(100% * 0.5);
        right: -70px;
        bottom: 20px;
      }
    }

    .lantern {
      position: absolute;
      &_left {
        width: calc(100% * 0.35);
        position: absolute;
        z-index: 1;
        transform: rotateY(180deg);
        left: -31px;
        bottom: -74px;
      }

      &_right {
        width: calc(100% * 0.35);
        position: absolute;
        right: 0;
        z-index: 1;
        right: -25px;
        bottom: -74px;
      }
    }

    .startBg {
      position: absolute;
      img {
        width: 100%;
      }
    }
  }

  .BgImage {
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .wrapper {
    position: relative;

    .placeholderBox {
      height: 348px;
    }

    .head {
      .whatTimeBeginAndEnd {
        width: 70vw;
        margin: 0 auto;
        display: block;
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translate(-50%, 0);
      }

      .rules {
        background-color: white;
        width: 54px;
        height: 30px;
        line-height: 30px;
        color: #b686be;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        text-align: center;

        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        padding-left: 5px;

        position: absolute;
        right: 0px;
        top: 35px;
        z-index: 3;
      }
    }

    .chancesWrapper {
      padding: 0 15px;
      margin-top: -65px;

      .chances {
        text-align: center;
        height: 299px;
        border-radius: 20px;
        padding-top: 73px;
        box-sizing: border-box;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center bottom;
        border-radius: 20px;
        border: 3px solid #fff;
        background: linear-gradient(
            57deg,
            rgba(242, 88, 255, 0.2) -5.04%,
            rgba(243, 104, 255, 0) 36.91%
          ),
          linear-gradient(308deg, rgba(139, 186, 255, 0.4) -15.66%, rgba(127, 224, 255, 0) 26.21%),
          #fefefe;

        .numberWrapper {
          background: linear-gradient(90deg, #095dff 11.31%, #e436e4 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;
          display: flex;
          justify-content: center;

          .chanceBox {
            display: flex;
            flex-direction: column;
            flex-direction: column-reverse;
            position: relative;
          }

          img {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            width: 60px;
            top: -17px;
          }

          .number {
            font-size: 49.427px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            background: linear-gradient(90deg, #0a5eff 11.31%, #5b4ff6 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            letter-spacing: -1.9px;
            height: 50px;
          }

          .write {
            background: linear-gradient(90deg, #684df4 11.31%, #e436e4 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: -0.48px;
            padding-left: 5px;
            position: relative;
            z-index: 2;
          }
        }

        .inviteText {
          color: #000;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 15px; /* 100% */
          padding: 10px 0;
          padding-bottom: 15px;
        }

        .shareUrlUnInvite {
          margin-top: 15px;
          color: var(--black-6007-d-7-d-7-d, #7d7d7d);
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 15px; /* 100% */
        }

        .overDate {
          color: #000;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 160% */
          margin-top: 5px;
        }
      }

      .shareUrl {
        position: relative;
        display: flex;
        justify-content: center;

        &_btn {
          width: 295px;
          height: 60px;
          border: 3px solid #fff;
          background: linear-gradient(278deg, #ffd85a 4.32%, #ff0a54 59.93%);
          border-radius: 100px;
          background-position: center;
          background-size: cover;

          color: #fff;
          text-shadow: 0px 0px 6px #ff7a00;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 60px;
          text-transform: uppercase;
          box-shadow: 0px 0px 11px 0px rgba(110, 114, 247, 0.2);
          position: relative;
          box-sizing: border-box;

          .hand {
            width: 34px;
            position: absolute;
            right: 15px;
            bottom: -5px;
          }
        }

        &_btnFailed {
          background: linear-gradient(
            173deg,
            rgba(255, 255, 255, 0.32) 8.27%,
            rgba(255, 255, 255, 0) 66.94%,
            rgba(255, 255, 255, 0) 66.94%
          );
          background-position: center;
          background-size: cover;

          color: #676767;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 60px;
          text-transform: uppercase;
          text-shadow: none;
        }
      }
    }

    .rankWrapper {
      margin-top: 20px;
      padding: 0 15px;
      position: relative;
      z-index: 2;

      .fireworks {
        width: calc(100% * 0.6);
        position: absolute;
        top: 10px;
        left: 50px;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      .referralHistory {
        border-radius: 20px;
        border: 3px solid #fff;
        background: linear-gradient(
            163deg,
            rgba(242, 88, 255, 0.2) -9.74%,
            rgba(243, 104, 255, 0) 18.08%
          ),
          linear-gradient(194deg, rgba(139, 186, 255, 0.4) -9.24%, rgba(127, 224, 255, 0) 16.06%),
          #fefefe;
        display: flex;
        padding: 1.5px 12px 20px 12px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        flex: 1 0 0;
        min-height: 336px;
        overflow-x: hidden;
        overflow-y: scroll;
      }

      .title {
        display: flex;
        width: 227px;
        padding: 15px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 0px 0px 20px 20px;
        background: linear-gradient(
          90deg,
          rgba(212, 226, 255, 0.6) 0.07%,
          rgba(240, 184, 255, 0.6) 99.93%
        );

        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;

        span {
          background: linear-gradient(90deg, #095dff 2.35%, #d738e6 99.99%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .tab {
      width: 100%;

      :global {
        .adm-tabs-tab {
          color: #80acff;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
        }

        .adm-tabs-header {
          border-bottom: 1px solid rgba(83, 142, 255, 0.12);
        }

        .adm-tabs-tab-active {
          color: #0058ff;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }

        .adm-tabs-tab-line {
          background: #0058ff;
        }
      }

      .tabContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        color: #121212;
        text-align: left;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;

        .february {
          color: var(--General-Dark-Grey, #707070);
          text-align: left;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          padding-top: 5px;
        }

        .notification {
          display: flex;
          padding: 6px 24px;
          justify-content: center;
          align-items: center;
          gap: 29px;
          border-radius: 100px;
          background: #dfeaff;

          color: #095dff;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }

      .tabContent:not(:first-child) {
        margin-top: 8px;
      }
    }

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 200px;
      padding-top: 20px;
      box-sizing: border-box;

      p {
        color: #7d7d7d;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin: 8px 0px 32px;
      }

      img {
        width: 88px;
        height: 88px;
      }

      .btn {
        display: flex;
        width: 154px;
        padding: 15px 18px;
        justify-content: center;
        align-items: center;
        gap: 29px;
        border-radius: 100px;
        background: #dfeaff;
        color: #095dff;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }

  // @media (min-width: 430px) {
  //   .wrapper {
  //     margin-top: -600px;
  //   }
  // }

  // @media (min-width: 450px) {
  //   .wrapper {
  //     margin-top: -600px;
  //   }
  // }

  // @media (min-width: 480px) {
  //   .wrapper {
  //     margin-top: -650px;
  //   }
  // }

  // @media (min-width: 500px) {
  //   .wrapper {
  //     margin-top: -690px;
  //   }
  // }

  // @media (min-width: 550px) {
  //   .wrapper {
  //     margin-top: -750px;
  //   }
  // }

  // @media (min-width: 600px) {
  //   .wrapper {
  //     margin-top: -800px;
  //   }
  // }

  // @media (min-width: 650px) {
  //   .wrapper {
  //     margin-top: -870px;
  //   }
  // }

  // @media (min-width: 700px) {
  //   .wrapper {
  //     margin-top: -940px;
  //   }
  // }

  // @media (min-width: 750px) {
  //   .wrapper {
  //     margin-top: -990px;
  //   }
  // }
}
