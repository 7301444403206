.loadingContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -90%);
  text-align: center;
  display: flex;
  flex-direction: column;
}
.loadingSpinner {
  // border: 4px solid rgba(0, 0, 0, 0.1);
  // border-top: 4px solid #2378e5;
  // border-radius: 50%;
  // width: 40px;
  // height: 40px;
  // animation: spin 1s linear infinite;
}

// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
