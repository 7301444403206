/* @import url('https://fonts.googleapis.com/css?family=Product+Sans:300,400,500,700,300i,400i,500i,700i&display=swap'); */
body,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
form,
dl,
dt,
dd {
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  line-height:1;
}
ul {
  list-style: none;
}
img {
  border-style: none;
}
a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  max-width: 750px;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
