/* .App {
  text-align: center;
} */

@keyframes ExChangeUSDT {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
@font-face {
  font-family: 'ProductSans';
  src: url(https://assets.bossjob.com/jobseeker/font/product-sans/ProductSansRegular.ttf),
    url(https://dev-assets.bossjob.com/jobseeker/font/product-sans/ProductSansRegular.ttf);
}
html,
body,
* {
  padding: 0;
  margin: 0;
  font-family: 'ProductSans';
}

body .crisp-client .cc-tlyw[data-full-view=true] .cc-kxkl .cc-nsge {
  bottom: 76px!important;
  right: 4px!important;
  z-index: 1!important;
}
html,body,#root, .App{
 height: 100%;
}