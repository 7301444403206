.shareRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  .shareCard {
    display: flex;
    flex-direction: column;
    // add this to allow img generator with same size
    width: 308px;
    height: 444px;
    min-height: 444px;
    // max-width: 375px;
    overflow: hidden;
    // border-radius: 26px 12px;
    background-color: transparent;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    position: relative;
    min-height: 105px;
    background-color: #fff;

    .bottom {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 10px 10px;
      overflow: hidden;

      .text {
        font-size: 18px;
        font-weight: 700;
        flex: 1;
        margin-top: -4px;

        @media (max-width: 400px) {
          font-size: 14px;
          // line-height: 18px;
        }
      }
      .QRCodeWrapper {
        width: 68px;
        height: 68px;
        border: 1px solid #fff;
        padding: 4px;
        border-radius: 4px;
        background-color: #fff;
        // margin-top: -2px;
        border: 1px solid #d7d7d7;
      }
    }
  }
  .bottomActionWrapper {
    box-sizing: border-box;
    display: inline-flex;
    padding: 30px 0px;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    flex-shrink: 0;
    border-radius: 16px 16px 0px 0px;
    background: var(--black-50-f-6-f-6-f-6-bg, #f6f6f6);
    border-radius: 21px 21px 0 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: 20px 0 0 0;

    @media (max-height: 667px) {
      margin: 15px 0 0 0;
    }

    .iconRoot {
      scrollbar-width: none;
      // overflow: auto;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    .iconWrapper {
      display: flex;
      gap: 10px;
    }
    .icon {
      min-width: 78px;
      text-align: center;
    }

    img {
      height: 50px;
      width: 50px;
    }
    .imageLabel {
      color: #000;
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .cancelBtn {
      appearance: none;
      border: none;
      width: 100%;
      display: flex;
      padding: 14px 0;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 100px;
      background: #fff;
      color: #000;
      font-size: 16px;
    }
  }
}
.imgResult {
  // width: 90%;
  max-width: 375px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  margin-top: 10px;

  img {
    max-width: 86%;
    max-height: 100%;
    width: 100%;
    // height: 100%;
    flex: 1;
    object-fit: contain;
  }

  .loadingWrapper {
    width: 300px;
    // max-width: 375px;
    height: 80%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 20px;
  }
}
