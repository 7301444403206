$marginBottom: 28px;

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 3.75rem;
  background-image: url('../static/award/winning-bg.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  // box-sizing: border-box;

  .rules {
    color: #B686BE;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;

    width: 54px;
    height: 30px;
    line-height: 30px;
    position: fixed;
    right: -1px;
    top: 30px;
    background-color: #ffffff;
    border-radius: 100px 0 0 100px;
    z-index: 10;
  }

  .animation {
    z-index: 1;
    width: 100%;
    margin-top: -68px;
    background: url('../static/award/starbg.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: auto 500px;
  }

  .box {
    // width: 316px;
    width: 39.5rem;
    height: 520px;
    flex-shrink: 0;

    border-radius: 20px;
    border: 3px solid #FEDF8A;
    background: linear-gradient(160deg, #C51800 25.09%, #DD253A 83.27%);
    box-sizing: border-box;
    margin-top: 26rem;
    position: absolute;
  }

  @media (max-width: 370px) {
    .box {
      margin-top: 24rem;
      height: 520px;
    }
  }

  @media (max-width: 300px) {
    .box {
      margin-top: 23rem;
      height: 520px;
    }
  }

  @media (min-width: 450px) {
    .box {
      margin-top: 26rem;
      height: 520px;
    }
  }

  @media (min-width: 500px) {
    .box {
      margin-top: 28rem;
      height: 530px;
    }
  }

  @media (min-width: 550px) {
    .box {
      margin-top: 28rem;
      height: 530px;
    }
  }

  @media (min-width: 600px) {
    .box {
      margin-top: 29rem;
      height: 540px;
    }
  }

  @media (min-width: 650px) {
    .box {
      margin-top: 29rem;
      height: 540px;
    }
  }

  @media (min-width: 700px) {
    .box {
      margin-top: 32rem;
      height: 550px;
    }
  }

  @media (min-width: 750px) {
    .box {
      margin-top: 34rem;
      height: 560px;
    }
  }

  .content {
    position: absolute;
    bottom: -$marginBottom;
    left: 0;
    right: 0;
    z-index: 2;
  }

  .title {
    // width: 232px;
    width: 29rem;
    margin: 0 auto;
    color: #FFDB5D;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .giftBox {
    margin-top: 14px;
    text-align: center;

    img {
      width: 206px;
    }
  }

  .giftInfo {
    width: 246px;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0 auto;
    margin-top: 14px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .formLabel {
    // width: 274px;
    width: 34rem;
    height: 42px;
    border-radius: 4px;
    background: #FFF;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
  }

  .formInput {
    outline: none;
    border: 0;
    background: none;
    height: 100%;
    flex: 1;
    padding: 0 10px;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .sendCode {
    display: block;
    color: #2378E5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    padding-right: 16px;
    cursor: pointer;
    flex-shrink: 0;
    height: 100%;
    line-height: 42px;
    // width: 200px;
  }

  .sendCode.disabled {
    color: #9E9E9E;
  }

  .formSubmit {
    width: 192px;
    height: 58px;
    flex-shrink: 0;
    margin-top: 22px;
    cursor: pointer;
    border-radius: 100px;
    user-select: none;
    overflow: hidden;

    border: 3px solid #FFF;
    background: url('../static/award/submit-disabled.png') no-repeat center;
    background-size: 192px 58px;
  }

  .formEnabled {
    border: 3px solid #FEDF8A;
    background: url('../static/award/submit-enabled.png') no-repeat center;
    background-size: 192px 58px;
  }
}

.last {
  background-image: url('../static/award/winning-lantern.png'), url('../static/award/winning-bg.png');
  background-repeat: no-repeat, no-repeat;
  background-position:
    center bottom,
    center top;
  background-size: auto, cover;

  .box {
    height: 432px;
  }

  .formView {
    border-radius: 4px;
    background: #A70006;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 20px 30px 20px;

    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    p {
      width: 100%;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .tip {
    padding: 0 20px;
    margin-bottom: $marginBottom+16px;

    color: #FFE6BD;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
}

.toastMessage {
  display: flex;
  word-break: break-word;
  align-items: center;
  text-align: center;
}

.pageLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.hide {
  display: none;
}